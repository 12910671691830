import { Injectable, OnDestroy } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Subject, interval, Subscription } from 'rxjs'
import { url } from '../../../environments/environment'
import { switchMap, tap, catchError, take } from 'rxjs/operators'
import { CurrentUserService } from './currentUser.service'
import { Metadata } from '../classes/Metadata'

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  private metadataUrl = '/metadata' // Adjust to your endpoint
  private checkInterval = 60 * 1000
  private subscription: Subscription
  public metadata$ = new Subject<any>()
  private broadcastChannel: BroadcastChannel
  public featureFlags: Record<string, boolean> = {}
  private reloadHash: string | null = null
  private onDestroy$: Subject<boolean> = new Subject()

  constructor(private http: HttpClient, private currentUser: CurrentUserService) {
    this.broadcastChannel = new BroadcastChannel('metadataChannel')
    this.broadcastChannel.addEventListener('message', (event) => {
      if (event.data.action === 'reload') {
        window.location.reload()
      }
    })
  }

  init() {
    this.fetchMetadata()
      .pipe(
        catchError((error) => {
          console.error(error)
          return error
        }),
        take(1)
      )
      .subscribe()
  }

  private startInterval(ms: number) {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.subscription = interval(ms)
      .pipe(switchMap(() => this.fetchMetadata()))
      .subscribe()
  }

  flag(key: string): boolean {
    return this.featureFlags[key] || false
  }

  fetchMetadata() {
    // return this.http.get<any>(`${url.base}${this.metadataUrl}`).pipe(

    // Mock function for testing
    return this.http.get<Metadata>(`${url.base}/v1/metadata`).pipe(
      tap(async (metadata) => {
        metadata.stateHashes = metadata.stateHashes || {}
        this.featureFlags = this.parseFeatureFlags(metadata.featureFlags)

        if (
          metadata?.metadataRefreshInterval &&
          metadata?.metadataRefreshInterval !== this.checkInterval
        ) {
          this.checkInterval = metadata.metadataRefreshInterval * 1000
          this.startInterval(this.checkInterval)
        }

        // Initialize the browser reload hash to prevent immediate reloads
        if (this.reloadHash === null) {
          this.reloadHash = metadata.stateHashes['browser-reload']
          localStorage.setItem('metadata.browser-reload', this.reloadHash)
        }

        // Initialize all the missing hashes in the local storage
        Object.keys(metadata.stateHashes).forEach((key) => {
          if (!localStorage.getItem(`metadata.${key}`)) {
            localStorage.setItem(`metadata.${key}`, metadata.stateHashes[key])
          }
        })

        await this.handleActions(metadata)
        this.metadata$.next(metadata)
      })
    )
  }

  private handleActions({ stateHashes }: Metadata) {
    try {
      // Forced local storage clear
      if (this.featureFlags.FORCE_LOCAL_STORAGE_CLEAN) {
        Object.keys(stateHashes).forEach((key) => {
          if (
            key.startsWith('local-storage-clear.') &&
            localStorage.getItem(`metadata.${key}`) !== stateHashes[key]
          ) {
            if (key.endsWith('.all')) {
              // Clear everything
              localStorage.clear()
            } else {
              // TODO: implement namespaces for local storage (user-settings, auth, cache, etc.)
              // Clear everything within the namespace
              const namespace = key.replace('local-storage-clear.', '')
              Object.keys(localStorage).forEach((k) => {
                if (k.startsWith(`${namespace}.`)) {
                  localStorage.removeItem(k)
                }
              })
              localStorage.setItem(`metadata.${key}`, stateHashes[key])
            }
          }
        })
      }

      // Forced logout
      if (
        this.featureFlags.FORCE_LOGOUT &&
        stateHashes['logout'] &&
        localStorage.getItem('metadata.logout') !== stateHashes['logout']
      ) {
        // TODO: we should probably use a method of the AuthService for this (it's repeated in multiple places)
        this.forceLogout()
      }

      // Forced refresh
      if (
        this.featureFlags.FORCE_REFRESH &&
        stateHashes['browser-reload'] &&
        this.reloadHash &&
        this.reloadHash !== stateHashes['browser-reload']
      ) {
        this.broadcastChannel.postMessage({ action: 'reload' })
      }
    } catch (e) {
      console.error('Error in Metadata service, action execution error: ', e)
    }
  }

  private async forceLogout() {
    await this.http
      .post(
        `${url.base}/logout`,
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }),
          withCredentials: true,
        }
      )
      .subscribe({
        next: (data) => {
          localStorage.removeItem('me')
          localStorage.removeItem('expiring_date')
          localStorage.removeItem('metadata.logout')
          window.location.reload()
        },
        error: (error) => {
          console.error(error.error.message)
        },
        complete: () => {},
      })
  }
  private parseFeatureFlags(featureFlags: any) {
    const user = this.currentUser.getCurrentUser()
    const userEmail = user?.email || ''
    const userRole = user?.role || ''
    const result: any = {}
    Object.keys(featureFlags || {}).forEach((flagKey) => {
      const flag = featureFlags[flagKey]
      if (typeof flag === 'boolean') {
        result[flagKey] = flag
      } else if (flag && flag.type && flag.value) {
        try {
          const parsedValue = JSON.parse(flag.value)
          if (flag.type === 'boolean') {
            result[flagKey] = parsedValue
          } else if (flag.type === 'users') {
            result[flagKey] = Array.isArray(parsedValue) && parsedValue.includes(userEmail)
          } else if (flag.type === 'roles') {
            result[flagKey] = Array.isArray(parsedValue) && parsedValue.includes(userRole)
          } else {
            result[flagKey] = false
          }
        } catch {
          result[flagKey] = false
        }
      }
    })
    return result
  }

  stop() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
