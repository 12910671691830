import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthGuard } from './shared'
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'
import { ToastrModule } from 'ngx-toastr'
import { NgSelectModule } from '@ng-select/ng-select'
import { AuthChildGuard } from './shared/guard/authChild.guard'
import { OverlayModule } from '@angular/cdk/overlay'
import { ThemeService } from './shared/services/theme.service'
import { AuthInterceptor } from './shared/interceptors/auth.interceptor'
import { CacheManagerInterceptor } from './shared/interceptors/cache-manager.interceptor'
import { CoreModule } from './core/core.module'
import { RefactorErrorsInterceptor } from './shared/interceptors/refactor.interceptor'
import { MetadataService } from './shared/services/metadata.service'
import { CurrentUserService } from './shared/services/currentUser.service'
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io'
// const config: SocketIoConfig = { url: url.base, options: {} }

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LanguageTranslationModule,
    NgSelectModule,
    AppRoutingModule,
    OverlayModule,
    CoreModule,
    ToastrModule.forRoot(),
    // SocketIoModule.forRoot(config),
  ],
  declarations: [AppComponent],
  providers: [
    AuthGuard,
    AuthChildGuard,
    ThemeService,
    CurrentUserService,
    MetadataService,
    { provide: HTTP_INTERCEPTORS, useClass: RefactorErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheManagerInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
